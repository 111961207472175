.container{
  padding: 0;
  background-color: #000000;
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  color: #FFFFFF;
}
.box{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 400px;
  background: #171717;
  padding:40px 58px;
  box-sizing: border-box;  
  text-align: center; 
}
.MetaMaskBox-tip{
  font-size: 24px;
  font-family: Rubik-Medium;
  font-weight: 500;
}
.MetaMaskBox-MetaMask{
  background: #00F56E;
  border-radius: 6px;
  border-radius: 6px;
  width: 100%;
  height: 48px;
  line-height: 48px;
  text-align: center;
  margin-top: 40px;
  color: #000000;
  font-family: Rubik-Medium;
  font-size: 16px;
  font-weight: 800;
}
.MetaMaskBox-TokenPocket{
  background: #2C2C2C;
  border-radius: 6px;
  border-radius: 6px;
  width: 100%;
  height: 48px;
  line-height: 48px;
  text-align: center;
  margin-top: 30px;
  color: #FFFFFF;
  font-family: Rubik-Medium;
  font-size: 16px;
  font-weight: 800;
}
#navbarText{
  justify-content: end;
  height: 100%;
  cursor: pointer;
}
.boxf2{
  display: inline-block;
  width: 30px;
  height: 28px;
  margin-right: 21px;
}
.boxf1{
  width: 30px;
  height: 19px;
  margin-right: 21px;
}
.chatcontainer{
  padding: 0;
  background-color: #000000;
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  color: #FFFFFF;
}
.imgbox{
   width: 256px;
   height: 256px;
}
.nft{
    position: absolute;
    width: 80px;
    height: 40px;
    line-height: 40px;
    z-index: 10;
    text-align: center;
    background: #00F56E;
    border-radius: 4px;
    right: 14px;
    bottom: 6px;
    color: #FFFFFF;
}
.popup{
  background: rgb(240, 240, 240);
  position: fixed;
  top: 40%;
  left: 50%;
  width: 200px;
  margin: auto;
  height: auto;
  line-height: 25px;
  padding:5px 10px;
  color: black;
  border-radius: 10px;
  z-index: 50;
  margin-left: -100px;
}
.nft:hover,.nft:active{
  background: #0fc558;
}
.chatbox{
  width: 100%;
  height: 100%;
  background: #000000;
  box-sizing: border-box;  
  text-align: center; 
  z-index: 10;
  overflow: hidden;
  border-radius: 10px;
}
.chatcontent{
  height: 100vh;
  width: 100%;
  padding:20px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 180px;
  padding-top: 60px;
}
.oneself{
  text-align: left;
  width: max-content;
  height: auto;
  color: #00F56E;
  background: #2D2D2D;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 15px;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-family: PingFangSC-Medium, PingFang SC;
  position: relative;
  overflow: hidden;
}
.oneselfBackground{
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
}
.myself{
  text-align: left;
  width: max-content;
  height: auto;
  font-weight: 500;
  color: #000000;
  background: #00F56E;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 15px;
  max-width: 80%;
  font-family: PingFangSC-Medium, PingFang SC;
  word-break: break-all;
}
.myselfBackground{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}
.askinput{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 100px;
  max-height: auto;
  padding: 10px;
  z-index: 10;
  background: #171717;
  box-sizing: border-box;
}
.askinput_header{
  width: 210px;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  border: 1px solid #FFD40D;
  margin-bottom:6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}
.askinput_header2{
  width: 210px;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  border: 1px solid #FFD40D;
  margin-bottom:6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  background-color: #FFD40D;
  color: #000000;
}
.textinput{
  min-height: 40px;
  max-height: auto;
  width: 78%;
  float: left;
  background: #2C2C2C;
  border-radius: 10px;
  border: none;
  text-align: left;
  line-height: 20px;
  padding: 10px;
}
.askinput_radio{
  display: inline-block;
  margin-right: 4px;
  width: 15px;
  height: 15px;
}
.sendbtn{
  width: 20%;
  height: 40px;
  background: #096dd9;
  text-align: center;
  line-height:40px;
  float: right;
  border-radius: 15px;
  margin-left: 5px;
  font-family: Rubik-Bold;
  font-weight: bold;
  color: #000000;
  background: #00F56E;
  position: fixed;
  bottom: 14px;
  right: 5px;
}
.ShareBtn{
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  position: absolute;
  right: 5px;
  top: 10px;
  border: 1px solid #FFD40D;
  border-radius: 20px;
}
.ShareBtn:active{
   opacity: 0.6;
}
/* pc端 */
@media (min-width: 600px) {
 .chatcontent{
   width: 50%;
   margin: auto;
   border: 2px solid #2D2D2D;
   border-radius: 30px;
   margin-top: 50px;
   padding-bottom: 300px;
  }
  .askinput{
    width: 50%;
    left: 50%;  
    transform: translate(-50%, -50%);
    bottom: -53px;
  }
}