.App {
  width: 100%;
  height: auto;
  background: #000000;
}
.App .container-fluid{
  padding: 0;
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  color: #FFFFFF;
  word-wrap: break-word; 
}
iframe {
  pointer-events: none; 
}
.container-fluid .navbar{
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  padding: 0;
  padding-left: 20px;
}
.MetaMaskBox{
  position: absolute;
  top: 80px;
  right: 0;
  width: 280px;
  height: 850px;
  background-color: #171717;
  z-index: 2;
  padding: 30px;
  box-sizing: border-box;
}
#navbarText{
  justify-content: end;
  height: 100%;
  cursor: pointer;
  display: flex!important;
  flex-basis: auto;
  margin-left: auto;
}
.navbar-brand{
  display: flex;
  align-items: center;
}
.navbar-brand  img{
  width: 140px;
}
#navbarText .navbar-HOME{
  line-height: 80px;
}
#navbarText .navbar-HOME:hover{
  color: #00F56E;
}
#navbarText .navbar-HOME:active{
  color: #00F56E;
}
#navbarText .navbar-NODES{
  color: #FFFFFF;
  margin-left: 60px;
}
#userheader .navbar-CONNECT{
  background-image: linear-gradient(90deg, #00E7FF 0%, #00F56E 52%, #FFD40D 100%);
  margin-left: 1%;
  height: 80px;
  line-height: 80px;
  font-family:Rubik-Bold;
  font-weight: 800;
  padding: 0 18px;
}
#userheader .navbar-CONNECT:hover{
  opacity: 0.6;
}
#userheader .navbar-CONNECT:active{
  opacity: 0.6;
}
#userheader .navbar-CONNECT .ant-btn{
  background-color: none !important;
  border: 0;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 80px;
  text-align: center;
  padding: 0;
  margin: 0;
}
.container-fluid .navbar-toggler {
  padding: 0;
}
.container-fluid .navbar-toggler .navbar-toggler-icon{
  background:url('./assets/image/header2.png');
  background-size: 100% 100%;
}
.walletconnect-modal__footer{
  width: 100%;
  display: block;
  overflow: auto;
}
.ant-typography-copy{
  height: 100%;
}
body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.UserInformation{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background: rgba(0,0,0,0.8);
}
.UserInformationbox{
  width: 90%;
  height: auto;
  height: auto;
  background: #171717;
  border-radius: 12px;
  margin: auto;
  margin-top: 35%;
  z-index: 5;
  position: relative;
  padding-bottom: 30px;
}
.closeInformation{
  position: absolute;
  right:20px;
  top: 20px;
  z-index: 5;
}
.UserIntest{
  height: auto;
  width: 90%;
  margin: auto;
}
.UserInfotext{
  font-size: 14px;
  font-family: Rubik-Regular;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 17px;
  margin-top: 10px;
  text-align: center; 
  padding-bottom: 20px;
  max-height: 300px;
  overflow: auto;
  position: relative;
}
.navbar-CONNECT .userinfor .identicon{
  position: relative;
  top: 2px;
}
.navbar-CONNECT .userinfor .ant-typography-copy{
  position: relative;
  top: -2px;
}
.Checkintoday abbr{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: black;
  background:#00F56E;
  display: block;
  text-align: center;
  line-height: 30px;
  margin: auto;
}
.userinfor{
  display: flex;
  align-items: center;
}
#navbarText div{
  width: auto;
  margin-left: 3%;
  min-width: 90px;
  text-align: center;
}

@media screen and (max-width: 1300px) { 
  /*当屏幕尺寸小于600px时，应用下面的CSS样式*/
  #navbarText div{
    width: auto;
    margin-left: 1%;
    max-width: 70px;
  }
  }
/* pc端 */
@media (min-width: 600px) {
  .UserInformationbox{
    width: 500px;
    margin-top: 10%;
    height: auto;
    padding-bottom: 30px;
  }
  .UserInfotext{
    font-size: 18px; 
    font-family: Rubik-Regular;
  }
 .navbar-CONNECT .ant-btn-round{
    background: none;
    border: none;
    float: right;
    line-height: 80px;
    padding: 0 20px;
    width: auto;
  }
  .navbar-CONNECT .userinfor{
    display: inline-block;
    height: 80px;
    line-height: 80px;
  }
  .navbar-CONNECT .userinfor .identicon{
    position: relative;
    top: 5px;
  }
  #userheader .navbar-CONNECT .ant-btn{
    float: right;
    padding: 0 10px;
    color: black;
    font-size: 16px;
    width: auto;
  } 
  #navbarText .navbar-HOME{
    font-size: 20px;
    font-family: Rubik-Regular;
    font-weight: 400;
    line-height: 80px;
  }
  .navbar-brand  img{
    width: 180px;
  }
}