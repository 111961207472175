.container{
    padding: 0;
    background-color: #000000;
    position: relative;
    width: 100vw;
    min-height: 100vh;
    max-height: auto;
    overflow: hidden;
    color: #FFFFFF;
    padding-top: 44px;
    overflow: auto;
  }
  .box{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 400px;
    background: #171717;
    padding:40px 58px;
    box-sizing: border-box;  
    text-align: center; 
  }
  .popup{
    background: rgb(240, 240, 240);
    position: fixed;
    top: 40%;
    left: 50%;
    width: 200px;
    margin: auto;
    height: auto;
    line-height: 25px;
    padding:5px 10px;
    color: black;
    border-radius: 10px;
    z-index: 50;
    margin-left: -100px;
  }
  .imgbox{
    width: 90%;
    margin: auto;
    margin-top: 20px;
  }
  .userimg{
    width: 100%;
    height: 100%;
  }
  .BindAccount{
    width: auto;
    margin: auto;
    height: 60px;
    margin-top: 40px;
    display: block;
    margin-bottom: 30px;
  }
  .Accountbox{
    width: 90%;
    margin: auto;
  }
  .Accountbox-son{
    float: left;
    width: 48%;
    padding: 12px;
    box-sizing: border-box;
    border: 2px solid #171717;
    margin-right: 12px;
    margin-bottom: 12px;
    border-radius: 12px;
  }
  .Accountbox-son2{
    float: left;
    width: 48%;
    padding: 12px;
    box-sizing: border-box;
    border: 2px solid #171717;
    margin-right: 0px;
    margin-bottom: 12px;
    border-radius: 12px;
  }

  .Bindimg{
    width: 100%;
    margin-bottom: 12px;
  }
  .Accountext{
    width: 90%;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background: linear-gradient(90deg, #AEF7FF 0%, #46D3F0 100%);
    border-radius: 16px;
    margin: auto;
    color: #000000;
  }
  .Accountext2{
    width: 90%;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background: linear-gradient(90deg, #EDDEFF 0%, #9479FF 100%);
    border-radius: 16px;
    margin: auto;
    color: #000000;
  }
  .Accountext3{
    width: 90%;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background: linear-gradient(90deg, #8AFFBE 0%, #00F56E 100%);
    border-radius: 16px;
    margin: auto;
    color: #000000;
  }
  .Accountext:hover,.Accountext2:hover, .Accountext3:hover{
       opacity: 0.6;
  }

 .UserCalendars{
  width: 90%;
  margin: auto;
 }
  .timeheader{
    width: 100%;
    margin-top: 40px;
    height: 28px;
    line-height: 28px;
  }
  .timeheader-left{
    float: left;
    width: 50%;
    font-size: 24px;
    font-family: Rubik-Medium;
    font-weight: 500;
    color: #FFFFFF;
  }
  .timeheader-right{
    float: right;
    width: 50%;
    font-size: 14px;
    font-family: Rubik-Regular;
    font-weight: 400;
    text-align: right;
  }
  .timeheader-num{
    color: #00F56E;
  }
  .signimg{
    width: 100%;
    display: block;
    margin: 20px 0 20px 0;
  }
  .Signed{
    width: 100%;
    text-align: center;
    font-weight: 400;
  }
.CONNECT{
  background-image: linear-gradient(
    90deg,
    rgba(0, 231, 255, 1) 0,
    rgba(0, 245, 110, 1) 51.654878%,
    rgba(255, 212, 13, 1) 99.928737%
  );
  border-radius: 24px;
  height: 48px;
  width: 260px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 60px;
  font-size: 20px;
  font-family: Rubik-Bold;
  font-weight: bold;
  color: #000000;
  text-align: center;
  border: none;
}
.checkIned{
  opacity: 0.6;
}

.bindbox{
  width: 90%;
  min-height: 250px;
  height: auto;
  background: #171717;
  border-radius: 12px;
  margin: auto;
  margin-top: 30%;
  z-index: 5;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
}
.bindtitle{
  font-size: 22px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  color: #00F56E;
}
.AccountNumber{
  text-align: left;
  font-size: 14px;
  font-family: Rubik-Medium;
  font-weight: 500;
  color: #FFFFFF;
  margin-bottom: 12px;
}
.Accountinput{
  width: 100%;
height: 47px;
background: #2C2C2C;
border-radius: 6px;
margin: auto;
border: none;
padding-left: 20px;
padding-right: 40px;
}
.Accountinput::-webkit-outer-spin-button,
.Accountinput::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
.Pointsline{
  width: 90%;
  margin: auto;
  border-bottom: 2px dashed #333333;
  margin-top: 10px;
}

.Accountinput:focus-visible{
  border: none;
}
.PointsBox-title{
  width: 100%;
  font-size: 16px;
  font-family: Rubik-Medium;
  font-weight: 500;
  color: #00F56E;
  line-height: 19px;
  margin: auto;
  text-align: center;
  margin-bottom: 30px;
}
.PointsBox-Address{
  width: 100%;
  word-wrap: break-word;
  text-align: center;
}
.button_1{
  background-image: linear-gradient(
    90deg,
    rgba(0, 231, 255, 1) 0,
    rgba(0, 245, 110, 1) 51.654878%,
    rgba(255, 212, 13, 1) 99.928737%
  );
  border-radius: 24px;
  height: 48px;
  width: 260px;
  margin: auto;
  margin-top: 20px;
  font-size: 20px;
  font-family: Rubik-Bold;
  font-weight: bold;
  color: #000000;
  text-align: center;
  border: none;
}
.button_1:hover,.button_1:active{
  opacity: 0.6;
}

.button_mining{
  background-image: linear-gradient(
    90deg,
    rgba(0, 231, 255, 1) 0,
    rgba(0, 245, 110, 1) 51.654878%,
    rgba(255, 212, 13, 1) 99.928737%
  );
  border-radius: 24px;
  height: 48px;
  width: 260px;
  margin-top: 20px;
  font-size: 20px;
  font-family: Rubik-Bold;
  font-weight: bold;
  color: #000000;
  text-align: center;
  border: none;
}
.button_mining:hover,.button_mining:active{
  opacity: 0.6;
}
.react-calendar{
  width: 90%;
  border-radius: 20px;
  margin: 20px auto;
  font-family: 'Rubik-Medium';
  position: relative;
}
.container_box{
  width: 100%;
  padding-top: 30px;
}
.container_box2{
  width: 100%;
}
.socialcontact{
  display: flex;
  width: 100%;
  height: auto;
  flex-wrap: wrap;
  justify-content: space-between;
  padding:0;
  text-align: center;
}
.Public-social{
  display: flex;
  width: 100%;
  height: auto;
  flex-wrap: wrap;
  justify-content: space-between;
  padding:0;
  text-align: center;
}
.socialcontactfollow{
  display: flex;
  width: 90%;
  height: auto;
  flex-wrap: wrap;
  justify-content: space-between;
  padding:0;
  text-align: center;
  margin: auto;
  margin-top: 10%;
}
.sharediv{
  height: auto;
  width: 30%;
  border: 1px solid #f5f5f5;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 10px;
  color: #f5f5f5;
}
.sharediv a{
  color: #f5f5f5;
}
.shareimg{
  display: block;
  width: 80%;
  margin: auto;
  margin-bottom: 10%;
}
.gofollow{
  width: 210px;
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  margin: auto;
  display: block;
  text-align: center;
  background: #23A2EF;
  color: #FFFFFF;
  border-radius: 10px;
  background: linear-gradient(90deg, #00E7FF 0%, #00F56E 52%, #FFD40D 100%);
  border-radius: 20px;
  font-size: 14px;
font-family: Rubik-Medium, Rubik;
font-weight: 500;
color: #000000;
cursor: pointer;
}
.gofollow:hover,.gofollow:active{
  opacity: 0.6;
}
.Points{
  width: 90%;
  height: 80px;
  background: linear-gradient(90deg, rgba(255,212,13,0.2) 0%, #342B03 0%, #1E1902 100%);
  border-radius: 12px;
  margin: auto;
  margin-top: 20px;
  padding: 20px;
  overflow: hidden;
  box-sizing: border-box;
}
.jf{
  width: 40px;
  height: 40px;
  float: left;
  margin: 0 20px;
}
.PointsNum{
  font-size: 18px;
  font-family: Rubik-Medium;
  font-weight: 500;
  color: #FFD40D;
  line-height: 40px;
  float: left;
  height: 100%;
  width: 110px;
  line-height: 25px;
}
.Points .PointBtn{
  width: auto;
  height: 39px;
  background: linear-gradient(90deg, #FFEC1E 0%, #FFD40D 100%);
  border-radius: 20px;
  font-size: 12px;
  font-family: Rubik-Medium, Rubik;
  font-weight: 500;
  color: #000000;
  line-height: 14px;
  line-height: 39px;
  float: right;
  padding: 0 10px;
}
.Points .PointBtn:hover,.Points .PointBtn:active{
  background: linear-gradient(90deg, #FFEC1E 0%, #FFD40D 100%);
}
.UserInvite_title{
    width: 90%;
    height: 27px;
    font-size: 22px;
    font-family: Rubik-Bold;
    font-weight: bold;
    color: #00F56E;
    line-height: 27px;
    margin: auto;
    margin-bottom: 20px;
}
.UserInvite_dsc{
  width: 90%;
  height: auto;
  margin: auto;
  font-size: 14px;
  font-family: PingFangTC-Regular, PingFangTC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
}
.UserInvite_box{
  width: 90%;
  margin: auto;
  height: auto;
  background: #171717;
  border-radius: 12px;
  padding: 12px;
  box-sizing: border-box;
  margin-top: 20px;
  margin-bottom: 20px;
}
.UserInvite_list,.UserInvite_list2{
   width: 100%;
   height: 26px;
   font-size: 12px;
   font-family: PingFangSC-Medium, PingFang SC;
   font-weight: 500;
   text-align: center;
   line-height: 26px;
}
.UserInvite_list_left{
  float: left;
  width: 42%;
  margin-right: 3%;
  height: 100%;
  background: rgba(0,245,110,0.1);
  border-radius: 6px;
  color: #00F56E;
}
.UserInvite_list_right{
  float: left;
  width: 55%;
  height: 100%;
  background: rgba(255,212,13,0.1);
  border-radius: 6px;
  color: #FFD40D;
}
.UserInvite_list2 .UserInvite_list_left,.UserInvite_list2 .UserInvite_list_right{
  background: none;
}
.UserInvBtn{
  width: auto;
  height: 40px;
  background: linear-gradient(90deg, #00E7FF 0%, #00F56E 52%, #FFD40D 100%);
  border-radius: 20px;
  font-size: 14px;
  font-family: Rubik-Medium, Rubik;
  font-weight: 500;
  color: #000000;
  cursor: pointer;
  padding: 0 24px;
  border: none;
  display: block;
  margin-left: 5%;
}
.UserInvBtn:hover,.UserInvBtn:active{
  opacity: 0.6;
}
.Nowpoints .UserInvBtn{
  margin: auto;
}
.UserInvline{
  width: 90%;
  margin: 30px auto;
  height: 1px;
  border-bottom:2px dashed #333333;
}
.nodeline{
  width: 100%;
  margin: 30px auto;
  height: 1px;
  border-bottom:2px dashed #333333;
}
.UserInvstep{
  width: 90%;
  display: block;
  margin: auto;
}
.Copylink{
  width: 90%;
  margin: auto;
  height: 130px;
  background: url(../image/UserInvitebc.png)
  100% no-repeat;
  background-size: 100% 100%;
  margin-top: 20px;
  text-align: center;
  padding: 25px;
  box-sizing: border-box;
}
.link{
  width: 100%;
  height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.friendsList{
  width: 90%;
  margin: auto;
  max-height: auto;
  overflow-x: hidden;
  overflow-y: auto;
  background: #171717;
  border-radius: 12px;
  padding: 12px;
  padding-bottom: 0;
  box-sizing: border-box;
}
.PublicBox_con{
  width: 100%;
height: auto;
margin: auto;
background: #171717;
border-radius: 12px;
padding: 12px;
box-sizing: border-box;
}
.friendsList_header{
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.header_list{
  width: 49%;
  height: auto;
  background: rgba(0,245,110,0.1);
  border-radius: 6px;
  font-size: 10px;
  font-family: NexaHeavy;
   color: #00F56E;
   padding:0 15px;
   line-height: 32px;
}
.Publicheader_list{
  width: 32%;
  height: auto;
  background: rgba(0,245,110,0.1);
  border-radius: 6px;
  font-size: 10px;
  font-family: NexaHeavy;
   color: #00F56E;
   padding:0 15px;
   line-height: 32px;
}
.header_list0{
  width: 23%;
  height: auto;
  background: rgba(0,245,110,0.1);
  border-radius: 6px;
  font-size: 10px;
  font-family: NexaHeavy;
   color: #00F56E;
   padding:0 15px;
   line-height: 12px;
   padding-top: 4px;
}
.notData{
  width: 100%;
  text-align: center;
  padding-top: 40px;
  height: 160px;
}
.Nocontent{
  width: 100%;
  height: 14px;
  font-size: 12px;
  font-family: Rubik-Medium;
  font-weight: 500;
  color: #999999;
  line-height: 14px;
}
.datalist{
  width: 100%;
  height: auto;
  max-height: 360px;
  overflow-y: auto;
}
.data_list{
  width: 100%;
  height: 72px;
  border-bottom: 1px solid #383838;
  display: flex;
  justify-content: space-between;
  padding:12px 0;
}
.list_address{
  width: 49%;
  height: 100%;
  word-break: break-all;
  overflow:hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.list_div{
  width: 49%;
  height: 100%;
  line-height: 40px;
}
.datafooter{
  width: 100%;
  height: 40px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-family: Rubik-Bold;
  font-weight: bold;
  color: #FFD40D;
}
.TotalPeople{
  width: 50%;
  text-align: left;
  text-indent: 10px;
}
.Totalpoints{
  width: 50%;
  text-align: left;
  text-indent: 10px;
}
.UserInvitebody,.mynodebody{
  width: 100%;
  height: auto;
  position: relative;
}
.UserInvitebody,.miningbody{
  width: 960px;
  margin: 20% 0% 20% 20%;
  font-size: large;
  /* margin: auto; */
}
.TokenVolume{
  width: 90%;
  height: 502px;
  background: #171717;
  border-radius: 12px;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
}
.TokenVolume_top{
  width: 100%;
  height: auto;
  text-align: center;
}
.TokenVolume_title{
  width: 100%;
  height: 17px;
  font-size: 14px;
  font-family: Rubik-Regular;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 17px;
  margin-top:10px;
}
.TokenVolume_Num{
  width: 100%;
  height: 27px;
font-size: 22px;
font-family: Rubik-Medium, Rubik;
font-weight: 500;
color: #00F56E;
line-height: 27px;
margin-top: 12px;
}
.TokenVolume_cen{
  display: flex;
  justify-content: space-between;
  height: auto;
  margin: 20px 0 30px 0;
}
.Available{
  width: 50%;
}
.Freeze{
  width: 50%;
}
.Available_top,.Freeze_top{
  height: 14px;
  font-size: 12px;
  font-family: Rubik-Regular;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 14px;
  margin-bottom: 12px;
}
.Available_bot,.Freeze_bot{
  height: 19px;
  font-size: 16px;
  font-family: Rubik-Medium;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 19px;
}
.NodeRevenue{
  width: 90%;
  height: 352PX;
  background: #171717;
  border-radius: 12px;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
  margin-bottom: 20px;
}
.Nowinvite,.myNowinvite{
  width: 90%;
  height: 130px;
  background: linear-gradient(90deg, #003919 0%, #0E1A0E 100%);
  border-radius: 12px;
  border: 2px solid #00F56E;
  margin: auto;
  box-sizing: border-box;
  margin-top: 20px;
}
.InviteFriends{
   width: 130px;
   float: left;
   height: 100%;
}
.InviteFriend{
  width: 130px;
}
.Nowpoints{
  float: left;
}
.Nowpoints_top{
  width: 161px;
  height: 41px;
  font-size: 16px;
  font-family: Rubik-Medium;
  font-weight: 500;
  color: #00F56E;
  line-height: 19px;
  margin-top: 20px;
  margin-left: 8px;
  margin-bottom: 12px;
}
.AIWorldNodes{
  width: 90%;
  height: 134px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-bottom: 20px;
}
.Numbernodes{
  width: 30%;
  height: 100%;
  background: #171717;
  border-radius: 12px;
}
.nodeimg{
  display: block;
  width: 50px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 12px;
}
.nodesTip,.nodesNum{
  width: 100%;
  text-align: center;
  height: 12px;
  font-size: 10px;
  font-family: Rubik-Regular;
  font-weight: 400;
  color: #00F56E;
  line-height: 12px;
}
.nodesNum{
  margin-top: 8px;
}
/* node */
.nodeheader{
  width: 100%;
  height: auto;
  position: relative;
  display: block;
}
.bugnodeheader{
  width: 100%;
  height: auto;
  position: relative;
  display: block;
}
.node_network{
  position: absolute;
  bottom: 100px;
  width: 80%;
  left: 10%;
  text-align: center;
  font-size: 18px;
  font-family: Rubik-Medium;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 22px;
  text-shadow: 0px 2px 8px rgba(0,0,0,0.5);
}
.node2header{
  width: auto;
  margin: auto;
  height: 40px;
  margin-top: 30px;
  display: block;
  margin-bottom: 30px;
}
.nodedaily{
  width: 90%;
  margin: auto;
}
.nodedaily_list1{
  width: 100%;
  height: auto;
  background: url(../image/daily1.png)   no-repeat;
  background-size: 100% 100%;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
} 
.nodedaily_list2{
  width: 100%;
  height: auto;
  background: url(../image/daily2.png)   no-repeat;
  background-size: 100% 100%;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.nodedaily_list3{
  width: 100%;
  height: auto;
  background: url(../image/daily3.png)   no-repeat;
  background-size: 100% 100%;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.nodedaily_title{
  width: 100%;
  height: 22px;
  font-size: 18px;
  font-family: Rubik-Medium;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 20px;
}
.nodedaily_text{
  width: 84%;
  margin: auto;
  height: auto;
  font-size: 14px;
  font-family: Rubik-Regular;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 17px;
  margin-top: 10px;
}
.nodeline{
  width: 90%;
  margin: auto;
  height: 1px;
  border-bottom: 2px dashed #333333;
  margin-top: 30px;
}
.bugnodeline{
  width: 90%;
  margin: auto;
  height: 1px;
  border-bottom: 2px dashed #333333;
  margin-top: 30px;
}
.box_13 {
  background-color: rgba(23, 23, 23, 1);
  border-radius: 12px;
  width: 90%;
  height: auto;
  margin: auto;
  margin-top: 20px;
  padding-bottom: 20px;
  /* display: none !important; */
}
.text-group_5 {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  padding-bottom: 0;
}
.text_20 {
  width: 100%;
  height: 51px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: Rubik-Regular; 
  text-align: center;
  line-height: 17px;
}

.text_21 {
  width: 100%;
  height: 34px;
  overflow-wrap: break-word;
  color: rgba(255, 212, 13, 1);
  font-size: 14px;
  font-family: Rubik-Regular;
  text-align: center;
  line-height: 17px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.section_5 {
  background-color: rgba(0, 245, 110, 0.1);
  border-radius: 6px;
  width: 86%;
  height: 26px;
  margin:auto;
  justify-content: space-around;
   padding: 6px 20px;
   font-weight: 500;
}
.section_6{
  border-radius: 6px;
  width: 86%;
  height: 26px;
  margin:auto;
  justify-content: space-around;
   padding: 6px 20px;
   font-weight: 500;
}

.text_22 {
  width: 49%;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
}
.thumbnail_3 {
  width: 1px;
  height: 12px;
}
.text_23 {
  width: 49%;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
}

.text_24 {
  width: 105px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
}
.text_42 {
  width: 90%;
  margin: auto;
  height: auto;
  overflow-wrap: break-word;
  color: rgba(255, 212, 13, 1);
  font-size: 14px;
  font-family: Rubik-Regular;
  text-align: center;
  white-space: break-spaces;
  line-height: 17px;
  margin-top: 20px;
}
.Frequently{
  width: 90%;
  height: 24px;
  font-size: 20px;
  font-family: NexaBlack;
  color: #00F56E;
  line-height: 24px;
  text-align: center;
  margin: auto;
  margin-top: 38px;
  margin-bottom: 28px;
}
.problemList{
  width: 90%;
  margin: auto;
  height: auto;
  background: #171717;
  border-radius: 12px;
  padding:10px 20px;
  box-sizing: border-box;
}
.problem_ul{
  width: 100%;
  padding: 0;
  height: auto;
}
.problem_li{
  list-style: none;
  width: 100%;
  height: auto;
  padding: 14px 0 16px 0;
  border-bottom: 1px solid #333333;
}
.problem_lititle{
  width: 100%;
  height: auto;
  font-size: 16px;
  font-family: Rubik-Medium;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.listimg{
  width: 20px;
  float: right;
  margin-left: 50px;
}
.problem_content{
  width: 100%;
  height: auto;
  font-size: 14px;
  font-family: Rubik-Regular;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
  margin-top: 16px;
}
.bugheader{
  width: 90%;
  margin: auto;
  display: block;
  margin-top: 20px;
}
.bugAIWorld{
   width: 90%;
   height: auto;
   margin: auto;
   padding-top: 20px;
   box-sizing: border-box;
}
.bugAIWorld .UserInvBtn{
  margin:auto;
  margin-top: 30px;
}
.bugnodestitle{
    width: 100%;
    height: 24px;
    font-size: 20px;
    font-family: Rubik-Medium;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 24px;
 }
 .bugnodesdes{
  width: 100%;
  height: auto;
  font-size: 14px;
  font-family: Rubik-Regular;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
  margin-top: 10px;
  margin-bottom: 10px;
 }

.progressBar{
  width: 160px;
  height: 8px;
  background: #303030;
  border-radius: 4px;
}
.progress{
  height: 8px;
  background: linear-gradient(90deg, #00E7FF 0%, #00F56E 52%, #FFD40D 100%);
  border-radius: 4px;
}
.SoldNodes{
  width: 100%;
  height: 14px;
  font-size: 10px;
  font-family: Rubik-Regular;
  font-weight: 400;
  color: #999999;
  line-height: 12px;
  margin-top: 6px;
  margin-bottom: 10px;
}
.RadioGroup .Radio{
  color: #FFFFFF;
}
.nodesinput{
 width: auto !important;
}
.bugbz{
  width: 16px;
  height: 16px;
  display: block;
  margin-right: 12px;
}
.refAddress{
   width: 80%;
   height: auto;
   word-wrap: break-word;
}
.bugnodesPrice{
  width: 100%;
  height: auto;
  margin-top: 20px;
  height: auto;
  line-height: 17px;
  display: inline-block;
  display: flex;
  align-items: center;
}
.bugleft{
  float: left;
  width: 30%;
}
.bugright{
  float: right;
  width: 70%;
  display: flex;
  align-items: center;
}

.PointsBox{
    display: flex;
    width: 90%;
    height: auto;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    text-align: center;
    margin: auto;
    margin-top: 10%;
}
.PointsBox_tip{
  width: 90%;
  margin: auto;
  margin-top: 15px;
}
.uid_tip{
  width: 90%;
  margin: auto;
  margin-top: 20px;
  color: rgba(153, 153, 153, 1);
}
.PointsBox-son{
  width: 48%;
  height: 134px;
  background: #171717;
  border-radius: 12px;
  margin-bottom: 20px;
  padding:30px 0;
  box-sizing: border-box;
  text-align: center;
}
.PointsBox-top{
  margin-bottom: 20px;
  font-size: 14px;
  font-family: Rubik-Regular, Rubik;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 17px;
  white-space: pre;
  width: 100%;
}
.PointsBox-Num{
  font-size: 22px;
  font-family: Rubik-Medium;
  font-weight: 500;
  color: #00F56E;
  line-height: 27px;
}
 .PointsBox-bindbox{
  width: 90%;
  height: auto;
  background: #171717;
  border-radius: 12px;
  margin: auto;
  margin-top: 30px;
  z-index: 5;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
}
.buynode-mc .PointsBox-bindbox{
  width: 90%;
  height: 170px;
  margin-bottom: 50px;
}
.PointsBox-tip{
  margin-top: 20px;
  font-size: 12px;
  color: #d9d9d9;
}
.backUserSignIn{
  color: #00F56E;
  width: 90%;
  margin: auto;
  height: 40px;
  line-height: 60px;
  cursor: pointer;
}
.Publicohb{
  position: absolute;
  right: 0;
  top: 0;
  height: 47px;
  line-height: 47px;
  width: 40px;
  color: #666666;
  font-size: 18px;
  text-align: center;
}
.Publictitle{
  width: 90%;
  height: 27px;
  font-size: 22px;
  font-family: Rubik-Bold, Rubik;
  font-weight: bold;
  color: #00F56E;
  line-height: 27px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 20px;
}
.Publicline{
  width: 90%;
  margin: auto;
  border-bottom: 2px dashed #333333;
  margin-top: 30px;
}
.PubliContent{
  width: 90%;
  margin: auto;
  height: auto;
  font-size: 14px;
  font-family: PingFangTC-Regular, PingFangTC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
  margin-top: 20px;
}
.offerContent{
  width: 90%;
  margin: auto;
  height: auto;
font-size: 14px;
font-family: PingFangTC-Regular, PingFangTC;
font-weight: 400;
color: #00E7FF;
line-height: 20px;
margin-bottom: 20px;
}
.tokenNUm{
  width: 100%;
  text-align: center;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}
.PublicBox{
  display: flex;
  width: 90%;
  height: auto;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  text-align: center;
  margin: auto;
  margin-top: 10%;
}
.PublicBox-title{
  width: 100%;
  height: 27px;
  font-size: 22px;
  font-family: Rubik-Bold, Rubik;
  font-weight: bold;
  color: #00F56E;
  line-height: 27px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: left;
}
.PublicMint{
  width: auto;
  height: 28px;
  background: linear-gradient(90deg, #00E7FF 0%, #00F56E 52%, #FFD40D 100%);
  border-radius: 16px;
  line-height: 28px;
  text-align: center;
  padding: 0px 22px;
  border: 0;
}
.Countdownin{
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-family: Rubik-Regular, Rubik;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 14px;
  margin-bottom: 12px;
}
.countdownDate{
   width: 100%;
   display: flex;
   padding-bottom: 50px;
}
.Date-list{
  text-align: center;
  flex: 1;
}
.colon{
  color: #00F56E;
  line-height: 33px;
}
.Date-listtop{
  width: 100%;
  height: 40px;
  font-size: 28px;
  font-family: Rubik-Medium, Rubik;
  font-weight: 500;
  color: #00F56E;
  line-height: 33px;
}
.Date-listbot{
  width: 100%;
  height: 12px;
  font-size: 10px;
  font-family: Rubik-Regular, Rubik;
  font-weight: 400;
  color: #666666;
  line-height: 12px;
}
/* pc端 */
@media (min-width: 600px) {
  .container{
    padding-top:0px;
  }
  .Pointscontainer{
    width: 760px;
    margin: auto;
  }
  .UserInvitebody,.mynodebody{
    width: 960px;
    margin: auto;
  }
  .UserInvitebody,.miningbody{
    width: 960px;
    margin: 20% 0% 20% 20%;
    font-size: xx-large;
    /* margin: auto; */
  }
  .buynode-mc .PointsBox-bindbox{
    width: 100%;
    height: 170px;
    margin-bottom: 50px;
  }
  .UserInvite_box{
    width: 360px;
    display: inline-block;
    margin-left:5%;
  }
  .header_list0{
    line-height: 26px;
  }
  .socialcontact{
    display: flex;
    width: 50%;
    height: auto;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 2% 10%;
    text-align: center;
    margin: auto;
  }
  .Public-social{
    display: flex;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 2% 0%;
    text-align: center;
    margin: auto;
  }
  .socialcontactfollow{
    display: flex;
    width: 500px;
    height: auto;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 50px;
  }
  .imgbox{
    width: 30%;
    margin: auto;
    float: left;
  }
  .container_box{
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin: auto;
    padding-top: 106px;
    margin-bottom: 20px;
  }
  .react-calendar{
    width: 400px;
  }
  .UserCalendars{
    width: 400px;
  }
  .backUserSignIn{
    color: #00F56E;
    width: 75%;
    margin: auto;
    height: 40px;
    line-height: 60px;
    cursor: pointer;
  }
  .Accountbox{
    width: 50%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    margin-bottom: 100px;
  }
  .Accountbox-son{
    float: left;
    width: 28%;
  }
  .Accountbox-son2{
    float: left;
    width: 28%;
  }
  .bindbox{
    width: 330px;
    margin-top:200px;
  }
  .container_box2 {
    display: flex;
    justify-content: space-between;
    width: 70%;
    height: 130px;
    line-height: 130px;
    margin: auto;
    }
    .uid_tip{
      text-align: center;
      margin-bottom: 200px;
    }
  .container_box2 .imgbox{
    width: 63%;
    height: 100%;
  }
  .container_box2 .Points{
    width: 30%;
    height: 100%;
    margin-top: 0;
    display: flex;
    align-items: center;
  }
  .PointsNum{
    line-height: 20px;
    width: 200px;
    padding-top: 33px;
  }
  .Copylink{
    background: url(../image/Invitebcpc.png)   100% no-repeat;
    background-size: 100% 100%;
  
  }
  .TokenVolume_top{
    float: left;
    width: 50%;
  }
  .TokenVolume{
    height: 251px;
  }
  .nodeline{
    display: none;
  }
  .NodeRevenue{
    height: 176px;
  }
  .InviteFriend{
    float: left;
    margin-left: 60px;
  }
  .InviteFriends{
    width: auto;
  }
  .Nowpoints_top{
    float: left;
    height: 68px;
    width: auto;
    margin: 30px 48px 30px 38px;
    line-height: 30px;
  }
  .Nowpoints{
    height: 100%;
  }
 .Nowinvite .Nowpoints .UserInvBtn{
  width: 240px;
  height: 40px;
  background: linear-gradient(90deg, #00E7FF 0%, #00F56E 52%, #FFD40D 100%);
  border-radius: 20px;
  margin-top: 45px;
  }
  .AIWorldNodes{
    height: 178px;
  }
  .nodeimg{
    margin-bottom: 20px;
  }
  .nodesTip{
    font-size: 14px;
  }
  .nodesNum{
    font-size: 16px;
    margin-top: 16px;
  }
  .Numbernodes{
    width: 32%;
  }
  .box_13{
    width: 720px;
    height: auto;
  }
  .text-group_5{
    width: 100%;
  }
  .section_5{
    width: 520px;
    margin: auto;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
     font-family: Rubik-Medium;
     font-weight: 500;
     color: #FFFFFF;
     padding: 12px 82px;
     justify-content: space-between;
   }
   .section_6{
     width: 520px;
     margin: auto;
     height: 40px;
     line-height: 40px;
     font-size: 14px;
      font-family: Rubik-Medium;
      font-weight: 500;
      color: #FFFFFF;
      padding: 12px 82px;
      justify-content: space-between;
   }
   .nodeheaderbc{
     width: 100vw;
   }
   .node2header{
    height: 60px;
   }
   .nodedaily{
    display: flex;
    justify-content: space-between;
    height: auto;
    width: 100%;
   }
   .nodedaily .nodedaily_list1{
    width: 32%;
   }
   .nodedaily .nodedaily_list2{
    width: 32%;
   }
   .nodedaily .nodedaily_list3{
    width: 33%;
   }
   .nodeheader{
    width: 960px;
    margin: auto;
    display: block;
   }

   .bugnodeheader{
    width: 960px;
    margin: auto;
    display: inline-block;
   }
   .nodedaily_title{
    height: auto;
    margin-top: 20px;
    margin-bottom: 40px;
   }
   .Nowinvite{
    width: 720px;
   }
   .myNowinvite{
    width: 90%;
   }
   .myNowinvite .Nowpoints_top{
    float: left;
    height: 68px;
    width: auto;
    margin: 30px 80px;
    line-height: 30px;
   }
   .myNowinvite  .UserInvBtn{
     float: right;
      margin-top: 40px;
      margin-left: 60px;
   }
   .problemList{
    width: 720px;
   }
   .bugheader{
    float: left;
    width: 30%;
   }
   .bugAIWorld{
    float: left;
    width: 70%;
    padding-left: 10%;
    box-sizing: border-box;
   }
   .bugAIWorld .UserInvBtn{
      margin-left: 0;
   }
   .SoldNodes{
    margin-bottom: 40px;
   }
   .bugnodeline{
    width: 100%;
   }
}