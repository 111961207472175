.container{
  padding: 0;
  background-color: #000000;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #FFFFFF;
  padding-top: 44px;
}

.nav-bar_1 {
  background-color: rgba(0, 0, 0, 1);
  width: 100%;
  height: 44px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}
.nav-bar{
  background-color: rgba(0, 0, 0, 1);
  width: 100%;
  height: 44px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}

.image-wrapper_1 {
  width: 140px;
  height: 20px;
  margin: 12px 0 0 20px;
}
.AIWORLD{
  width: auto;
  height: auto;
  background: linear-gradient(90deg, #00E7FF 0%, #00F56E 52%, #FFD40D 100%);
  margin: auto;
  text-align: center;
  font-size: 62px;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 800;
  margin-bottom: 20px;
  margin-top: 80px;
}
.ai{
  width: 426px;
  height: 436px;
}
.image_1 {
  width: 140px;
  height: auto;
}
.boxf2{
  display: inline-block;
  width: 30px;
  height: 28px;
  margin-right: 21px;
}
.boxf1{
  width: 30px;
  height: 19px;
  margin-right: 21px;
}
.label_1 {
  width: 24px;
  height: 24px;
  margin: 10px 20px 0 0;
}
.box_1 {
  height: 100vh;
  background-image: url(./image/headerbc.png);
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 40px;
}

.text_1 {
  width: 312px;
  height: 44px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  line-height: 22px;
  margin-top: 69vh;
}
.footerimg{
  width: 150px;
}

.button_1,.button_4{
  background-image: linear-gradient(
    90deg,
    rgba(0, 231, 255, 1) 0,
    rgba(0, 245, 110, 1) 51.654878%,
    rgba(255, 212, 13, 1) 99.928737%
  );
  border-radius: 24px;
  height: 48px;
  width: 260px;
  margin: auto;
  margin-top: 20px;
  font-size: 20px;
  font-family: Rubik-Bold;
  font-weight: bold;
  color: #000000;
  text-align: center;
  border: none;
}
.button_1:hover,.button_4:hover{
  opacity: 0.6;
}
.button_1:active,.button_4:active{
  opacity: 0.6;
}
.text_2 {
  width: 173px;
  height: 48px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 20px;
  font-family: Rubik-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 48px;
  
}

.label_2 {
  width: 40px;
  height: 40px;
  margin: 30px 0 40px 0;
  -webkit-animation: bounce-down 1.6s linear infinite;
  animation: bounce-down 1.6s linear infinite;
}

.image_4 {
  position: absolute;
  top: 153px;
  width: 355px;
  height: 331px;
}

.box_2 {
  height: auto;
  background: url(./image/box2bc.png)
    100% no-repeat;
  background-size: 100% 100%;
  width: 100%;
  position: relative;
}

.section_1 {
  width: auto;
  height: 60px;
  margin: 30px 0 0 0;
}

.thumbnail_1 {
  width: 100%;
  height: 100%;
}


.box_3 {
  background-color: rgba(0, 245, 110, 1);
  border-radius: 50%;
  width: 4px;
  height: 4px;
  margin: 1px 0 0 0px;
}

.text_3 {
  width: 90px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(0, 245, 110, 1);
  font-size: 22px;
  
  
  text-align: center;
  white-space: nowrap;
  line-height: 28px;
  margin-left: 7px;
}

.box_4 {
  background-color: rgba(0, 245, 110, 1);
  border-radius: 50%;
  width: 4px;
  height: 4px;
  margin: 22px 0 0 0px;
}

.thumbnail_2 {
  width: 10px;
  height: 12px;
  margin: 3px 5px 0 5px;
}

.section_3 {
  border-radius: 12px;
  background-image: url(./image/bc2.png);
  height: 160px;
  width: 90%;
  margin-top: 24px;
  background-size: 100% 100%;
  text-align: center;
}

.text-wrapper_1 {
  border-radius: 12px;
  height: auto;
  width: 80%;
  margin: auto;
  margin-top: 18%;
  
}

.text_4 {
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.9);
  width: 300px;
  height: 54px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 15px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  line-height: 18px;
  margin: 54px 0 0 18px;
}

.text-wrapper_2 {
  height: auto;
  background: url(./image/bc3.png)
    100% no-repeat;
  background-size: 100% 100%;
  margin-top: 20px;
  width: 100%;
  padding-bottom: 30px;
}

.text_5 {
  width: 90%;
  height: auto;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: Rubik-Regular;
  font-weight: 500;
  text-align: center;
  line-height: 18px;
}

.box_5 {
  position: relative;
  width: 100%;
  height: auto;
}

.group_1 {
  width: 300px;
  height: 45px;
  margin: 30px 0 0 0px;
}

.text-wrapper_3 {
  background-image: url(./image/title2.png);
  height: 40px;
  width: 300px;
  background-size: 100% 100%;
}

.text_6 {
  width: 250px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(0, 245, 110, 1);
  font-size: 22px; 
  text-align: center;
  white-space: nowrap;
  line-height: 28px;
  margin: 5px 0 0 0;
}

.text-wrapper_4 {
  width: 287px;
  height: 22px;
  margin: 10px 0 10px 0px;
}

.text_7 {
  width: 287px;
  height: 17px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: Rubik-Regular;
  
  text-align: center;
  white-space: nowrap;
  line-height: 17px;
}

.text-wrapper_5 {
  width: 231px;
  height: 24px;
  margin: 40px 0 0 370px;
}

.text_8 {
  width: 231px;
  height: 24px;
  overflow-wrap: break-word;
  color: rgba(0, 231, 255, 1);
  font-size: 20px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
}

.text-wrapper_6 {
  width: 295px;
  height: 187px;
  margin: 10px 0 240px 370px;
}

.text_9 {
  width: 295px;
  height: 187px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: Rubik-Regular;
  
  text-align: left;
  line-height: 17px;
}

.group_2 {
  border-radius: 12px;
  position: absolute;
  left: 350px;
  top: 117px;
  width: 25px;
  height: 261px;
  border: 2px solid rgba(0, 231, 255, 1);
}

.box_6 {
  border-radius: 12px;
  border: 2px solid rgba(0, 245, 110, 1);
  width: 85%;
  padding: 20px;
  margin: auto;
}

.text_10 {
  width: auto;
  height: 24px;
  overflow-wrap: break-word;
  color: rgba(0, 245, 110, 1);
  font-size: 20px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
}

.label_3 {
  width: 28px;
  height: 28px;
}

.text_11 {
  width: 100%;
  height: 187px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: Rubik-Regular;
  font-weight: 400;  
  text-align: left;
  line-height: 17px;
  overflow: hidden;
  margin-top: 20px;
}

.box_8 {
  width: 100%;
  height: auto;
}

.image_5 {
  width: 88px;
  height: 6px;
  margin: 20px 0 0 0;
}



.text_12 {
  width: 173px;
  height: 24px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 20px;
  font-family: Rubik-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
  
}

.image_6 {
  width: 90%;
  height: 1px;
  margin: 30px 0 0 0px;
}

.text-wrapper_7 {
  background-image: url(./image/token.png);
  height: 40px;
  width: 260px;
  margin: 33px 0 21px 0px;
  background-size: 100% 100%;
}
.text-wrapper_8 {
  border-radius: 12px;
  background-image: url(./image/centerbc.png);
  height: 200px;
  width: 90%;
  background-size: 100% 100%;
  margin: auto;
}

.paragraph_1 {
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  width: 100%;
  height: auto;
  padding: 37px 17px;
  color: rgba(255, 255, 255, 1);
  font-size: 15px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  line-height: 18px;
  box-sizing: border-box;
  word-wrap: break-word;
  padding-top: 68px;
}

.box_9 {
  background-color: rgba(23, 23, 23, 1);
  border-radius: 12px;
  width: 90%;
  height: auto;
  justify-content: flex-center;
  margin: auto;
  margin-top: 20px;
  padding-bottom: 10px;
}

.text_14 {
  width: 100%;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(255, 212, 13, 1);
  font-size: 18px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  text-align: center;
  margin-top: 20px;
}
.image-text {
  width: 295px;
  height: 34px;
  margin: 10px 0 0 20px;
  height: 28px;
}

.box_10 {
  background-color: rgba(255, 212, 13, 1);
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin-top: 14px;
}

.text-group{
  width: 280px;
  height: 34px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: Rubik-Regular;
  text-align: left;
  line-height: 17px;
}

.image-text_2 {
  width: 287px;
  height: 34px;
  margin: 10px 0 0 20px;
}

.box_11 {
  background-color: rgba(255, 212, 13, 1);
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin-top: 14px;
}


.image-text_3 {
  width: 287px;
  height: 34px;
  margin: 10px 0 0 20px;
}

.section_4 {
  background-color: rgba(255, 212, 13, 1);
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin-top: 14px;
}

.image-text_4 {
  width: 262px;
  height: 17px;
  margin: 10px 0 20px 20px;
}

.boxdd {
  background-color: rgba(255, 212, 13, 1);
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin-top: 6px;
  float: left;
  margin-right: 5px;
}
.text-wrapper_9 {
  background-color: rgba(23, 23, 23, 1);
  border-radius: 12px;
  width: 90%;
  height: auto;
  justify-content: flex-center;
  margin: auto;
  margin-top: 20px;
  padding-bottom: 20px;
}

.text_15 {
  width: 156px;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(255, 212, 13, 1);
  font-size: 18px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin: 20px 0 0 86px;
}

.text_16 {
  width: 100%;
  height: auto;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: Rubik-Regular;
  text-align: left;
  line-height: 17px;
  padding: 20px;
  box-sizing: border-box;
}

.text_17 {
  width: 100%;
  height: auto;
  overflow-wrap: break-word;
  color: rgba(255, 212, 13, 1);
  font-size: 14px;
  font-family: Rubik-Regular;
  text-align: left;
  line-height: 17px;
  padding:0 20px;
  box-sizing: border-box;
}
.box_13 {
  background-color: rgba(23, 23, 23, 1);
  border-radius: 12px;
  width: 90%;
  height: auto;
  margin: auto;
  margin-top: 20px;
  padding-bottom: 20px;
  /* display: none !important; */
}

.text-group_5 {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  padding-bottom: 0;
}

.text_20 {
  width: 100%;
  height: 51px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: Rubik-Regular; 
  text-align: center;
  line-height: 17px;
}

.text_21 {
  width: 100%;
  height: 34px;
  overflow-wrap: break-word;
  color: rgba(255, 212, 13, 1);
  font-size: 14px;
  font-family: Rubik-Regular;
  text-align: center;
  line-height: 17px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.section_5 {
  background-color: rgba(0, 245, 110, 0.1);
  border-radius: 6px;
  width: 86%;
  height: 26px;
  margin:auto;
  justify-content: space-around;
   padding: 6px 20px;
   font-weight: 500;
}
.section_6{
  border-radius: 6px;
  width: 86%;
  height: 26px;
  margin:auto;
  justify-content: space-around;
   padding: 6px 20px;
   font-weight: 500;
}

.text_22 {
  width: 49%;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
}

.thumbnail_3 {
  width: 1px;
  height: 12px;
}

.text_23 {
  width: 49%;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
}

.text_24 {
  width: 105px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
}

.thumbnail_4 {
  width: 1px;
  height: 12px;
  margin: 1px 0 0 20px;
}

.text_25 {
  width: 90px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin-left: 28px;
}

.section_7 {
  background-color: rgba(0, 245, 110, 0.1);
  border-radius: 6px;
  width: 287px;
  height: 26px;
  margin: 6px 0 0 24px;
}

.text_26 {
  width: 105px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin: 6px 0 0 18px;
}

.thumbnail_5 {
  width: 1px;
  height: 12px;
  margin: 7px 0 0 20px;
}

.text_27 {
  width: 89px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin: 6px 25px 0 29px;
}

.section_8 {
  width: 244px;
  height: 14px;
  margin: 6px 0 0 42px;
}

.text_28 {
  width: 105px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
}

.thumbnail_6 {
  width: 1px;
  height: 12px;
  margin: 1px 0 0 20px;
}

.text_29 {
  width: 90px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin-left: 28px;
}

.section_9 {
  background-color: rgba(0, 245, 110, 0.1);
  border-radius: 6px;
  width: 287px;
  height: 26px;
  margin: 6px 0 0 24px;
}

.text_30 {
  width: 114px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin: 6px 0 0 14px;
}

.thumbnail_7 {
  width: 1px;
  height: 12px;
  margin: 7px 0 0 15px;
}

.text_31 {
  width: 90px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin: 6px 25px 0 28px;
}

.section_10 {
  width: 251px;
  height: 14px;
  margin: 6px 0 0 37px;
}

.text_32 {
  width: 116px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
}

.thumbnail_8 {
  width: 1px;
  height: 12px;
  margin: 1px 0 0 14px;
}

.text_33 {
  width: 92px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin-left: 28px;
}

.section_11 {
  background-color: rgba(0, 245, 110, 0.1);
  border-radius: 6px;
  width: 287px;
  height: 26px;
  margin: 6px 0 0 24px;
}

.text_34 {
  width: 119px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin: 6px 0 0 12px;
}

.thumbnail_9 {
  width: 1px;
  height: 12px;
  margin: 7px 0 0 12px;
}

.text_35 {
  width: 90px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin: 6px 25px 0 28px;
}

.section_12 {
  width: 251px;
  height: 14px;
  margin: 6px 0 0 37px;
}

.text_36 {
  width: 116px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
}

.thumbnail_10 {
  width: 1px;
  height: 12px;
  margin: 1px 0 0 14px;
}

.text_37 {
  width: 92px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin-left: 28px;
}

.section_13 {
  background-color: rgba(0, 245, 110, 0.1);
  border-radius: 6px;
  width: 287px;
  height: 26px;
  margin: 6px 0 0 24px;
}

.text_38 {
  width: 116px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin: 7px 0 0 13px;
}

.thumbnail_11 {
  width: 1px;
  height: 12px;
  margin: 7px 0 0 14px;
}

.text_39 {
  width: 92px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin: 7px 23px 0 28px;
}

.section_14 {
  width: 252px;
  height: 14px;
  margin: 7px 0 0 36px;
}

.text_40 {
  width: 118px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
}

.thumbnail_12 {
  width: 1px;
  height: 12px;
  margin-left: 13px;
}

.text_41 {
  width: 92px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
  margin-left: 28px;
}

.text_42 {
  width: 90%;
  margin: auto;
  height: auto;
  overflow-wrap: break-word;
  color: rgba(255, 212, 13, 1);
  font-size: 14px;
  font-family: Rubik-Regular;
  text-align: center;
  white-space: break-spaces;
  line-height: 17px;
  margin-top: 20px;
}

.text_43 {
  width: 173px;
  height: 24px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 20px;
  font-family: Rubik-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
  
}

.box_14 {
  width: 100%;
  height: 420px;
  background: url(./image/bcfooter.png)
    100% no-repeat;
  background-size: 100% 100%;
  background-position: -8px -87px;
}

.block_2 {
  width: 335px;
  height: 180px;
  margin: 38px 0 0 0px;
}



.text-group_6 {
  width: 98px;
  height: 90px;
  margin: 70px 0 0 30px;
}

.text_44 {
  width: 98px;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(255, 212, 13, 1);
  font-size: 18px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
}

.paragraph_2 {
  width: 98px;
  height: 56px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Regular;
  
  text-align: center;
  line-height: 14px;
  margin-top: 12px;
}
.section_16 {
  background-color: rgba(23, 23, 23, 1);
  border-radius: 12px;
  position: relative;
  width: 158px;
  height: 180px;
}

.text-group_7 {
  width: 98px;
  height: 90px;
  margin: 70px 0 0 30px;
}

.text_45 {
  width: 98px;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(205, 36, 250, 1);
  font-size: 18px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
}

.paragraph_3 {
  width: 98px;
  height: 56px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Regular;
  
  text-align: center;
  line-height: 14px;
  margin-top: 12px;
}

.image_7 {
  width: 335px;
  height: 1px;
  margin: 30px 0 0 0px;
}

.text_46 {
  width: auto;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(0, 245, 110, 1);
  font-size: 22px;
  font-weight: 500;
  white-space: nowrap;
  line-height: 22px;
}
.text_47 {
  width: auto;
  height: 22px;
  overflow-wrap: break-word;
  color: #00E7FF;
  font-size: 22px;
  font-weight: 500;
  white-space: nowrap;
  line-height: 22px;
}
.text_48 {
  width: auto;
  height: 22px;
  overflow-wrap: break-word;
  color: #FFD40D;
  font-size: 22px;
  font-weight: 500;
  white-space: nowrap;
  line-height: 22px;
}
.text_50 {
  color: rgba(205, 36, 250, 1);
  width: auto;
  height: 22px;
  overflow-wrap: break-word;
  font-size: 22px;
  font-weight: 500;
  white-space: nowrap;
  line-height: 22px;
}
.box_15 {
  width: 100%;
  height: auto;
  margin-bottom: 1px;
}

.block_3 {
  width: 335px;
  height: 319px;
}

.block_4 {
  width: 6px;
  height: 319px;
}

.box_16 {
  background-color: rgba(0, 245, 110, 1);
  border-radius: 4px;
  width: 6px;
  height: 149px;
}

.box_17 {
  background-color: rgba(0, 231, 255, 1);
  border-radius: 4px;
  width: 6px;
  height: 149px;
  margin-top: 21px;
}

.list_1 {
  width: 309px;
  height: 318px;
  justify-content: space-between;
}

.text-group_8-0 {
  background-color: rgba(23, 23, 23, 1);
  border-radius: 12px;
  width: 309px;
  height: 149px;
  margin-bottom: 20px;
  justify-content: flex-center;
}

.text_47-0 {
  width: 77px;
  height: 22px;
  overflow-wrap: break-word;
  font-size: 18px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin: 16px 0 0 16px;
  color: rgba(0, 245, 110, 1);
}

.text_48-0 {
  width: 269px;
  height: 85px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: Rubik-Regular;
  
  text-align: left;
  line-height: 17px;
  margin: 10px 0 16px 16px;
}

.text-group_8-1 {
  background-color: rgba(23, 23, 23, 1);
  border-radius: 12px;
  width: 309px;
  height: 149px;
  margin-bottom: 20px;
  justify-content: flex-center;
}

.text_47-1 {
  width: 77px;
  height: 22px;
  overflow-wrap: break-word;
  font-size: 18px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin: 16px 0 0 16px;
  color: rgba(0, 231, 255, 1);
}

.text_48-1 {
  width: 269px;
  height: 85px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: Rubik-Regular;
  
  text-align: left;
  line-height: 17px;
  margin: 10px 0 16px 16px;
}

.block_5 {
  width: 90%;
  height: 169px;
  margin: 20px 0 0 0px;
}
.section_15{
  background-color: #00F56E;
  border-radius: 4px;
  width: 6px;
  height: 166px;
  margin-top: 3px;
}
.section_16{
  background-color: #00E7FF;
  border-radius: 4px;
  width: 6px;
  height: 166px;
  margin-top: 3px;
}
.section_17 {
  background-color: rgba(255, 212, 13, 1);
  border-radius: 4px;
  width: 6px;
  height: 166px;
  margin-top: 3px;
}

.section_18 {
  background-color: rgba(23, 23, 23, 1);
  border-radius: 12px;
  width: 309px;
  height: 166px;
  padding: 16px;
  box-sizing: border-box;
  font-size: 14px;
}

.text-group_9 {
  width: 281px;
  height: 134px;
  overflow: hidden;
}

.text_49 {
  width: 74px;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(255, 212, 13, 1);
  font-size: 18px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}

.paragraph_4 {
  width: 281px;
  height: 102px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: Rubik-Regular; 
  text-align: left;
  line-height: 20px;
  margin-top: 10px;
  overflow: auto;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
}
.paragraph_4::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.block_6 {
  width: 335px;
  height: 115px;
  margin: 20px 0 0 0px;
}

.box_18 {
  background-color: rgba(205, 36, 250, 1);
  border-radius: 4px;
  width: 6px;
  height: 115px;
}

.box_19 {
  background-color: rgba(23, 23, 23, 1);
  border-radius: 12px;
  width: 309px;
  height: 115px;
}

.text-group_10 {
  width: 278px;
  height: 83px;
  margin: 16px 0 0 16px;
}



.paragraph_5 {
  width: 278px;
  height: 51px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: Rubik-Regular;
  
  text-align: left;
  line-height: 17px;
  margin-top: 10px;
}

.text_51 {
  width: 173px;
  height: 24px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 20px;
  font-family: Rubik-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
  
}

.image-wrapper_2 {
  width: 140px;
  height: 20px;
  margin: 80px 0 0 20px;
}

.image_8 {
  width: 22px;
  height: 16px;
  margin: 2px 0 0 1px;
}
.image_9 {
  width: 140px;
}

.image-wrapper_3 {
  width: 177px;
  height: 30px;
  justify-content: space-between;
  margin: 10px 0 0 20px;
}
.image-wrapper_3 a:hover{
  opacity: 0.6;
}
.icon_1-0 {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}


.icon_1-1 {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.icon_1-2 {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.icon_1-3 {
  width: 30px;
  height: 30px;
  margin-right: 0px;
}

.text-wrapper_11 {
  width: 231px;
  height: 22px;
  margin: 9px 0 0 24px;
}

.text_52 {
  width: 54px;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}

.text_53 {
  width: 102px;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}

.text-wrapper_12 {
  width: 182px;
  height: 14px;
  margin: 10px 0 0 24px;
}

.text_54 {
  width: 36px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-family: Rubik-Regular;
  
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
}

.text_55 {
  width: 53px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-family: Rubik-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
}

.text-wrapper_13 {
  width: 174px;
  height: 14px;
  margin: 10px 0 0 24px;
}

.text_56 {
  width: 55px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-family: Rubik-Regular;
  
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
}
.text_color:active, .text_color:hover {
  color: rgba(0, 245, 110, 1);
}

.text_57 {
  width: 45px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-family: Rubik-Regular;
  
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
}

.text-wrapper_14 {
  width: 172px;
  height: 14px;
  margin: 10px 0 0 24px;
}

.text_58 {
  width: 74px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-family: Rubik-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
}
.Security{
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-family: Rubik-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
}

.text_59 {
  width: 43px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-family: Rubik-Regular;
  
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
}

.text-wrapper_15 {
  width: 170px;
  height: 14px;
  margin: 10px 0 0 24px;
  display: flex;
  justify-content: space-between;
}
.user_footer{
  margin-bottom: 50px;
}
.text_60 {
  width: 41px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-family: Rubik-Regular;
  
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
}

.box_20 {


}

.image_10 {
  width: 90%;
  height: 1px;
  margin: 30px 0 0 0px;
}

.text-wrapper_16 {
  background-image: url(./img/0678c30eadfb433eb409a26342159864_mergeImage.png);
  height: 50px;
  width: 260px;
  margin: 30px 0 0 0px;
}

.paragraph_6 {
  width: aut0;
  height: 48px;
  overflow-wrap: break-word;
  color: rgba(0, 245, 110, 1);
  font-size: 20px;
  font-family: Rubik-Medium;
  font-weight: 800;
  text-align: center;
  line-height: 24px;
  margin:auto;
}

.block_8 {
  border-radius: 12px;
  width: 90%;
  height: auto;
  border: 2px solid #00F56E;
  justify-content: flex-center;
  margin: 20px 0 0 0px;
}

.text_61 {
  width: 88%;
  height: 84px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 17px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background: none;
  border: none;
}

.box_21 {
  width: 95%;
  height: 36px;
  margin: 0px 0 20px 0px;
}

.button_5 {
  background-color:#00F56E;
  border-radius: 6px;
  height: 36px;
  width: 20%;
}

.thumbnail_13 {
  width: 20px;
  height: 20px;
  margin: 8px 8px 0 0;
}

.text_62 {
  width: 12px;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin: -21px 0 7px 32px;
}

.group_3 {
  background-color: #00F56E;
  border-radius: 6px;
  width: 70%;
  height: 36px;
  align-items: center;
}

.text_63 {
  height: 17px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
  line-height: 17px;
  padding-left: 37%;
  font-family: Rubik-Bold, Rubik;
  font-weight: bold;
  color: #000000;
}

.image_11 {
  width: 43px;
  height: 8px;
  margin-left:12px;
}

.image_12 {
  width: 90%;
  height: 1px;
  margin: 30px 0 0 0px;
}
.image_13 {
  width: 90%;
  height: 1px;
  margin-bottom: 30px;
}
.coreteam{
  width: 300px;
  margin-top: 30px;
}
.group_4 {
  position: relative;
  width: 127px;
  height: 32px;
  background: url(./img/SketchPngdb8615756847002583746df5395c8a2d87dd70762b559316061c52ab36b72581.png)
    100% no-repeat;
  background-size: 100% 100%;
  margin: 4px 0 0 40px;
}

.text_64 {
  width: 114px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(0, 245, 110, 1);
  font-size: 22px;
  text-align: center;
  white-space: nowrap;
  line-height: 28px;
  margin: 1px 0 0 5px;
}

.box_22 {
  background-color: rgba(0, 245, 110, 1);
  border-radius: 50%;
  width: 4px;
  height: 4px;
  margin: 25px 0 0 px;
}

.box_23 {
  background-color: rgba(0, 245, 110, 1);
  border-radius: 50%;
  position: absolute;
  left: 5px;
  top: 1px;
  width: 4px;
  height: 4px;
}
.block_2999-0 {
  margin-top: 43px;
  width: 100%;
  height: auto;
  background: url(./image/bcfooter.png);
  background-size: 100% 100%;
}
.block_10 {
  background-color: rgba(23, 23, 23, 1);
  border-radius: 12px;
  position: relative;
  width: 90%;
  height: auto;
  margin: auto;
  margin-bottom: 40px;
  font-size: 12px;
  font-family: Rubik-Regular;
  font-weight: 400;
}
.Introduction{
  width: 100%;
  height: 53px;
  overflow: hidden; 
  display: -webkit-box;
  -webkit-line-clamp: 3; 
  -webkit-box-orient: vertical;
  margin-top: 6px;
}
.single-more{
  width: auto;
  color: #00F56E;
  position: absolute;
  top: 18px;
  right: 20px;
}
.text_65 {
  width: 100%;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(0, 245, 110, 1);
  font-size: 18px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
}

.paragraph_7 {
  width: 98px;
  height: 56px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Regular;
  
  text-align: center;
  line-height: 14px;
  margin-top: 12px;
}

.single-avatar{
  border-radius: 50%;
  display: block;
  background-image: url(./image/Ioannis.png);
  position: relative;
  width: 86px;
  height: 86px;
  margin: auto;
  top: -23px;
}
.text-group_11 {
  height: 90px;
  margin: auto;
  text-align: center;
  width: 90%;
  position: relative;
  top: -10px;
}
.block_11 {
  background-color: rgba(23, 23, 23, 1);
  border-radius: 12px;
  position: relative;
  width: 158px;
  height: 180px;
}

.text-group_12 {
  width: 98px;
  height: 90px;
  margin: 70px 0 0 30px;
}

.text_66 {
  width: 98px;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(0, 231, 255, 1);
  font-size: 18px;
  font-family: Rubik-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
}

.paragraph_8 {
  width: 98px;
  height: 56px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-family: Rubik-Regular;
  
  text-align: center;
  line-height: 14px;
  margin-top: 12px;
}


@-webkit-keyframes bounce-down {
 25% {
      -webkit-transform: translateY(-4px);
 }
 50%, 100% {
      -webkit-transform: translateY(0);
 }
 75% {
      -webkit-transform: translateY(4px);
 }
}

@keyframes bounce-down {
  25% {
       transform: translateY(-4px);
  }
  50%, 100% {
       transform: translateY(0);
  }
  75% {
       transform: translateY(4px);
  }
}

.page-swiper {
  width: 100%;
  margin-left: 8px;
}

.box_999 {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 44px;
    z-index: 10;
    background: black;
    overflow: hidden;
    z-index: 100;
}
.CONNECT{
  background-image: linear-gradient(
    90deg,
    rgba(0, 231, 255, 1) 0,
    rgba(0, 245, 110, 1) 51.654878%,
    rgba(255, 212, 13, 1) 99.928737%
  );
  border-radius: 24px;
  height: 48px;
  min-width: 260px;
  max-width: auto;
  padding:0 20px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 60px;
  font-size: 20px;
  font-family: Rubik-Bold;
  font-weight: bold;
  color: #000000;
  text-align: center;
  border: none;
}
.text_200 {
  width: 58px;
height: 24px;
font-size: 20px;
font-family: Rubik-Medium, Rubik;
font-weight: 500;
color: #FFFFFF;
line-height: 24px;
margin: 120px 0 80px 0;
}
.text_201 {
  width: 58px;
height: 24px;
font-size: 20px;
font-family: Rubik-Medium, Rubik;
font-weight: 500;
color: #FFFFFF;
line-height: 24px;
margin-bottom: 240px;
}
.HOME{
  width: 100%;
  text-align: center;
  height: 24px;
  font-size: 20px;
  font-family: Rubik-Medium;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 20px;
  margin-top: 60px;
}
.INTEGRAL{
  width: 100%;
  text-align: center;
  height: 24px;
  font-size: 20px;
  font-family: Rubik-Medium;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 20px;
  min-width: 93px;
}
.INTEGRAL:hover{
  color: #00F56E;
}
.language{
    width: 100%;
    text-align: center;
    height: 24px;
    font-size: 20px;
    font-family: Rubik-Medium;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 30px;
    color: #FFFFFF;
}

.PARTNERSHIP{
  width: 100%;
  text-align: center;
  height: 24px;
  font-size: 20px;
  font-family: Rubik-Medium;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 20px;
  color:#FFFFFF;
}
.PARTNERSHIP:hover{
  color: #00F56E;
}
.icon_999-0 {
  margin-right: 20px;
}

/* pc端 */
@media screen and (max-width: 1300px) { 
  /*当屏幕尺寸小于600px时，应用下面的CSS样式*/
  }
@media (min-width: 600px) {
  .box_1 {
    height: 105vh;
    background-image: url(./image/pcheader.png);
    position: relative;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 0;
  }
  .box_2 {
    height: auto;
    width: 63%;
    margin: auto;
  }
  .text-wrapper_2{
    width: 63%;
    margin: auto;
    margin-top: 30px;
  }
  .section_3 {
    border-radius: 12px;
    background-image: url(./image/bc2.png);
    height: 320px;
    width: 670px;
    background-size: 100% 100%;
  }
  .text-wrapper_1 {
    font-family: Rubik-Medium;
    font-size: 30px;
    margin-top: 21%;
  }
  .coreteam{
    margin-top: 60px;
  }
  .text_5{
    font-family: Rubik-Regular;
    font-size: 18px;
    text-align: center;
    line-height: 30px;
    width: 80%;
  }
  .container-fluid .adm-swiper-slide{
    justify-content: space-between;
    width: 80%;
    margin: auto;
  }
  .box_6{
     box-sizing: border-box;
     height: 300px;
     width: 100%;
  }
  .image_6{
    width: 64%;
    margin-top: 60px;
  }
  .text-wrapper_8{
    width: 670px;
    height: 400px;
    margin: auto;
  }
  .paragraph_1{
    width: 80%;
    margin: auto;
    font-size: 28px;
    color: #FFFFFF;
    line-height: 33px; 
    padding-top:0;
  }
  .box_9{
    width: 720px;
    height: auto;
    background: #171717;
    border-radius: 12px;
    margin: auto;
    margin-top: 30px;
  }
  .text-wrapper_9{
    width: 720px;
    height: auto;
    background: #171717;
    border-radius: 12px;
    margin: auto;
    margin-top: 30px;
    padding: 30px;
    box-sizing: border-box;
  }
  .text-wrapper_9 span{
    margin: 0;
    width: 100%;
    height: auto;
  }
  .text-group{
    width: 95%;
    height: 34px;
    text-align: left;
    padding-left: 20px;
    font-size: 16px;
    float: left;
    height: 34px;
  }
  .image-text{
    line-height: 34px;
    width: 100%;
    font-family: Rubik-Regular;
    font-size: 16px;
  }
  .text-wrapper_7 {
    background-image: url(./image/token.png);
    height: 40px;
    width: 260px;
    margin: 60px 0 21px 0px;
    background-size: 100% 100%;
  }
  .group_1{
    height: auto;
    width: 450px;
    margin-top: 60px;
  }
  .text-wrapper_3 {
    background-image: url(./image/title2.png);
    height: 60px;
    width: 450px;
    background-size: 100% 100%;
  }
  .text_14 {
    margin-bottom: 30px;
  }
  .text-group_5{
    width: 100%;
  }
  .box_13{
    width: 720px;
    height: auto;
  }
  .section_5{
   width: 520px;
   margin: auto;
   height: 40px;
   line-height: 40px;
   font-size: 14px;
    font-family: Rubik-Medium;
    font-weight: 500;
    color: #FFFFFF;
    padding: 12px 82px;
    justify-content: space-between;
  }
  .section_6{
    width: 520px;
    margin: auto;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
     font-family: Rubik-Medium;
     font-weight: 500;
     color: #FFFFFF;
     padding: 12px 82px;
     justify-content: space-between;
  }
  .image_10,.image_12,.image_13{
    width: 68%;
  }
  
  .block_8{
    width: 600px;
    height: 360px;
    border-radius: 24px;
    border: 4px solid rgba(0,245,110,0.3);
  }
  .text_61{
    height: 240px;
    border: none;
  }
  .text_63{
    font-size: 20px;
  }
  .box_14 {
    width: 66%;
    height: auto;
    margin: auto;
    background: url(./image/bcfooter.png)
      100% no-repeat;
    background-size: 100% 100%;
  }
  .block_2999-0{
    width: 56%;
    margin: auto;
    padding-top: 40px;
    background-size: 50%;
    background-repeat:no-repeat;
    background-position:right;
    justify-content: space-between;
    display: flex;
    padding-bottom: 30px;
  }
  .block_10 {
    width: 30%;
  }
  .text_1{
    margin-top: 0;
  }
  .block_5{
    width: auto;
  }
  .section_18,.box_19{
    margin-left: 20px;
  }
  .footer{
    width: 80%;
    margin: auto;
    padding-left: 20%;
  } 
  .button_4{
    position: relative;
    left: -147px;
  }
  .lastfooter{
    width: 100%;
    height: 400px;
    background: url(./image/footerbc.png)
      100% no-repeat;
    background-size: 100% 100%;
    margin-top: 60px;
  }
  .footerleft{
    float: left;
    height: 100%;
    width: 50%;
    padding-left: 160px;
    box-sizing: border-box;
  }
  .footerleft a img{
    width: 45px;
    height: 45px;
  }
  .footerright{
    float: left;
    width: 50%;
    height: 100%;
    padding-top: 60px;
  }
  .lastfooter .image-wrapper_3{
    margin-top: 100px;
  }
  .lastfooter .text-wrapper_11{
    width: 346px;
    font-size: 27px;
    font-family: Rubik-Medium;
    font-weight: 500;
  }
  .lastfooter .text-wrapper_12{
    width: 300px;
    margin-top: 28px;
  }
  .lastfooter .text-wrapper_13{
    width: 295px;
    margin-top: 14px;
  }
  .lastfooter .text-wrapper_14{
    width: 293px;
    margin-top: 14px;
  }
  .lastfooter .text-wrapper_15{
    width: 292px;
    margin: 10px 0 0 24px;
  }
  .INTEGRAL{
    width: auto;
    text-align: center;
    height: 100%;
    font-size: 20px;
    font-family: Rubik-Regular;
    font-weight: 400;
    line-height: 80px;
    margin:0 0 0 3%;
  }
  .PARTNERSHIP{
    width: auto;
    text-align: center;
    height: 100%;
    font-size: 20px;
    font-family: Rubik-Regular;
    font-weight: 400;
    line-height: 80px;
    margin:0 0 0 40px;
  }
  .footerimg{
    margin-left: -325px;
    width: 225px;
  }
 .language{
  width: auto;
  margin-left:1%;
  min-width: 85px;
  text-align: center;
  height: 100%;
  font-size: 20px;
  font-family: Rubik-Regular;
  font-weight: 400;
  line-height: 80px;
  }
  .text_55 {
    text-indent: 2px;
  }
}