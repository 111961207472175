.App {
  width: 100%;
  height: auto;
  background: #000000;
}
body {
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App .container-fluid{
  padding: 0;
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  color: #FFFFFF;
}
.container-fluid .navbar{
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  padding: 0;
  padding-left: 20px;
}
.MetaMaskBox{
  position: absolute;
  top: 80px;
  right: 0;
  width: 280px;
  height: 100%;
  background-color: #171717;
  z-index: 2;
  padding: 30px;
  box-sizing: border-box;
  text-align: center;
}
#navbarText{
  justify-content: end;
  height: 100%;
  cursor: pointer;
}
.navbar-brand  img{
  width: 140px;
}
#navbarText .navbar-NODES{
  color: #FFFFFF;
  margin-left: 60px;
}
#userheader .navbar-CONNECT{
  background-image: linear-gradient(90deg, #00E7FF 0%, #00F56E 52%, #FFD40D 100%);
  margin-left: 60px;
  height: 80px;
  line-height: 80px;
  font-family:Rubik-Bold;
  font-weight: 800;
  padding: 0 18px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-width: 305px;
}
.container-fluid .navbar-toggler {
  padding: 0;
}
.container-fluid .navbar-toggler .navbar-toggler-icon{
  background:url('./image/header2.png');
  background-size: 100% 100%;
}
.MetaMaskBox-MetaMask{
  background: #00F56E;
  border-radius: 6px;
  border-radius: 6px;
  width: 220px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  margin-top: 40px;
  color: #000000;
  font-family: Rubik-Medium;
  font-size: 16px;
  font-weight: 800;
}
.MetaMaskBox-TokenPocket{
  background: #2C2C2C;
  border-radius: 6px;
  border-radius: 6px;
  width: 220px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  margin-top: 30px;
  color: #FFFFFF;
  font-family: Rubik-Medium;
  font-size: 16px;
  font-weight: 800;
}

.page-swiper {
  width: 100%;
}
.adm-page-indicator-horizontal .adm-page-indicator-dot-active{
  background-color: #00F56E;
}
.adm-page-indicator-horizontal .adm-page-indicator-dot-active{
  background-color: #00F56E;
}
.react-calendar__month-view__weekdays{
  height: 40px;
  background: #171717;
  border-radius: 20px;
  width: 100%;
  line-height: 40px;
  padding: 0 20px;
  text-align: center;
  box-sizing: border-box;
  margin-top: 20px;
}
.react-calendar__month-view__days{
  background: #171717;
  border-radius: 20px;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 12px;
}
.react-calendar__month-view__weekdays abbr[title]{
  text-decoration: none;
}
.react-calendar__tile{
  height: 45px;
  width: 45px;
  border-radius: 50%;
  line-height: 45px;
}
.react-calendar__navigation{
  width: 200px;
  font-size: 20px;
  position: absolute;
  top: -50px;
  left: 0;
}

.fade-in-out,.fade-in-out2,.fade-in-out3,.fade-in-out4{
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.fade-in-out.visible, .fade-in-out2.visible,.fade-in-out3.visible,.fade-in-out4.visible{
  opacity: 1;
}
.fade-in-outheader{
  opacity: 0;
  transition: opacity 2s ease-in-out;
}
.fade-in-outheader.visible{
  opacity: 1;
}
.text-fade-in,.btn-fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.text-fade-in.visible,.btn-fade-in.visible{
  opacity: 1;
  transform: translateY(0);
}

@media (min-width: 600px) {
.container-fluid .adm-swiper-slide .adm-swiper-item{
  justify-content: space-between !important;
  width: 100%;
  margin: auto;
  padding: 0 18%;
  box-sizing: border-box;
  margin-top: 5px;
  margin-bottom: 10px;
}
.Swiperbox{
  width: 32%;
  box-sizing: border-box;
  overflow: hidden;
}
.rightdiv{
  position: relative;
  left: -353px;
}
}